<script lang="ts" setup>
	import HelpIcon from '~icons/fivb/help';
	import StatsIcon from '~icons/fivb/stats';
	import { UserType } from '@/sdk';
	import MenuDropdown from '@/ui/Navigation/MenuDropdown.vue';
	import MenuItem from '@/ui/Navigation/MenuItem.vue';
	import { useAuth } from '~/app/auth/composables/use-auth';
	import { SpecialUser } from '~/domain/user/special-user';

	const isSmall = inject('isSmall');
	const currentUser = useAuth();

	const canAccessAdmin = computed(() => {
		const type = currentUser.type;

		return type === UserType.Supervisor || type === UserType.Administrator;
	});

	const canAccessFooChanges = computed(() => {
		const userNo = currentUser.no;

		return [2, 3, 44, 89, 96, 30225, 17139, 46936, 48235, 52558].some((no) => no === userNo);
	});

	const canAccessDevelopment = computed(() => {
		const userNo = currentUser.no;

		return [
			SpecialUser.JulienRipouteau,
			SpecialUser.RomainLanz,
			SpecialUser.AlbertoRigamonti,
			SpecialUser.HiteshMalhotra,
			SpecialUser.HugoDerycke,
			SpecialUser.PatrickDeBuren,
			SpecialUser.ThomaLiechti,
			SpecialUser.TestDevFederation,
			SpecialUser.TestDevFrFederation,
		].some((no) => no === userNo);
	});

	const canAccessMediaNewFeature = computed(() => {
		const userNo = currentUser.no;

		return [SpecialUser.RomainLanz, SpecialUser.AlbertoRigamonti].some((no) => no === userNo);
	});
</script>

<template>
	<nav class="flex flex-1 flex-col justify-between gap-2" aria-label="Sidebar">
		<div class="space-y-2">
			<RouterLink
				to="/"
				class="focus:outline-hidden group flex w-full items-center rounded-md bg-white text-gray-600"
				:class="{
					'py-2 pl-2 pr-1 hover:bg-blue-50 hover:text-blue-900 focus:bg-blue-50 focus:text-blue-900': !isSmall,
					'pointer-events-none justify-center text-gray-400': isSmall,
				}"
			>
				<StatsIcon
					class="size-6 group-hover:text-blue-500"
					:class="{
						'mr-4 text-gray-400': !isSmall,
						'm-0 text-gray-500': isSmall,
					}"
				/>

				<span v-if="!isSmall">Dashboard</span>
			</RouterLink>
			<MenuDropdown name="Global" icon="globe">
				<MenuItem to="/federations">Federations</MenuItem>
				<MenuItem to="/referees">Referees</MenuItem>
				<MenuItem to="/officials">Officials</MenuItem>
				<MenuItem to="/players">Players</MenuItem>
			</MenuDropdown>
			<MenuDropdown name="Volleyball" icon="volleyball">
				<MenuItem to="/coaches">Coaches</MenuItem>
				<MenuItem to="/referees?active=volley">Referees</MenuItem>
				<MenuItem to="/volley/clubs">Clubs</MenuItem>
				<MenuItem to="/volley/transfers">Transfers</MenuItem>
				<MenuItem to="/volley/tournaments">Tournaments</MenuItem>
				<MenuItem v-if="canAccessFooChanges" to="/volley/national-league/seasons">NL Seasons</MenuItem>
			</MenuDropdown>
			<MenuDropdown name="Beach Volley" icon="beachvolley">
				<MenuItem to="/referees?active=beach">Referees</MenuItem>
				<MenuItem to="/beach/tournaments">Tournaments</MenuItem>
				<MenuItem to="/beach/rankings">Rankings</MenuItem>
			</MenuDropdown>
			<MenuDropdown v-if="canAccessFooChanges" name="Legal" icon="legal">
				<MenuItem to="/legal/foo-changes">FoO Changes</MenuItem>
				<MenuItem to="/legal/determinations-of-federation">Determinations of Federation</MenuItem>
			</MenuDropdown>
			<MenuDropdown name="Medical" icon="medical">
				<MenuItem to="/medical-staff">Medical Staff</MenuItem>
			</MenuDropdown>

			<MenuDropdown v-if="canAccessDevelopment" name="Development" icon="development">
				<MenuItem to="/development/projects">Projects</MenuItem>
				<MenuItem v-if="canAccessAdmin" to="/development/shipments">Shipments</MenuItem>
			</MenuDropdown>
			<MenuDropdown name="Media" icon="media">
				<MenuItem to="/media-staff">Media Staff</MenuItem>
				<MenuItem v-if="canAccessMediaNewFeature" to="/media/articles">Articles</MenuItem>
				<MenuItem v-if="canAccessMediaNewFeature" to="/media/albums">Gallery</MenuItem>
			</MenuDropdown>
			<MenuDropdown name="Administration" icon="tools" :disabled="!canAccessAdmin">
				<MenuItem to="/admin/users/online">Online Users</MenuItem>
				<MenuItem to="/admin/users">Users</MenuItem>
			</MenuDropdown>
		</div>

		<div v-if="!isSmall" class="space-y-2">
			<!--			<a-->
			<!--				class="group flex w-full items-center rounded-md bg-white py-2 pl-2 pr-1 text-gray-600 hover:bg-blue-50 hover:text-blue-900 focus:outline-hidden focus:ring-2 focus:ring-blue-500"-->
			<!--				href="https://www.fivb.org/vis2009/downloadapplication.asmx?Code=VIS"-->
			<!--			>-->
			<!--				<DownloadIcon class="mr-4 h-6 w-6 rotate-90 text-gray-400 will-change-transform group-hover:text-blue-500" />-->
			<!--				VIS Desktop-->
			<!--			</a>-->
			<a
				class="focus:outline-hidden group flex w-full items-center rounded-md bg-white py-2 pl-2 pr-1 text-gray-600 hover:bg-blue-50 hover:text-blue-900 focus:bg-blue-50 focus:text-blue-900"
				href="https://help.app.fivb.com"
				target="_blank"
				rel="noreferrer"
			>
				<HelpIcon class="mr-4 size-6 text-gray-400 group-hover:text-blue-500" />
				Help
			</a>

			<div class="divide-x divide-gray-300 pt-8 text-right">
				<RouterLink class="pr-2 text-sm text-gray-500 hover:underline" to="/privacy-policy">Privacy Policy</RouterLink>
				<span class="pl-2 text-sm text-gray-500">{{ appVersion }} &copy; FIVB</span>
			</div>
		</div>
	</nav>
</template>
